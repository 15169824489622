<template>
  <div class="change">
    <!-- 图片切换开始 -->
    <div class="img-change">
      <img style="width: 100%" :src="item.img" alt="" />
      <div class="img-change-option hidden-xs-only">
        <!-- 左切换按钮开始 -->
        <div class="change-btn" @click="changeImgContent(0)">
          <img
            v-show="lists.length - 5 - bannerChangeIndex > 0"
            src="../assets/img/left-img.png"
            alt=""
          />
        </div>
        <!-- 左切换按钮结束 -->
        <!-- 切换图片开始 -->
        <div class="img-change-content">
          <!-- 切换图片容器开始 -->
          <div class="img-change-select" ref="selectContent">
            <div
              class="img-select-item"
              v-for="(item, index) in lists"
              :key="index"
              @click="selectBigBanner(item)"
            >
              <img :src="item.img" style="width: 100%; height: 100%" />
            </div>
          </div>
          <!-- 切换图片容器开始 -->
          <!--  -->
        </div>
        <!-- 切换图片结束 -->
        <!-- 右切换按钮开始 -->
        <div class="change-btn" @click="changeImgContent(1)">
          <img
            v-show="bannerChangeIndex != 0"
            src="../assets/img/right-img.png"
            alt=""
          />
        </div>
        <!-- 右切换按钮结束 -->
      </div>
    </div>
    <!-- 图片切换结束 -->
    <!-- 小屏切换开始 -->
    <div class="img-change-sm hidden-sm-and-up">
      <!-- 左切换按钮开始 -->
      <div class="sm-change-btn" @click="changeImgContent2(0)">
        <img
          v-show="lists.length - 4 - bannerChangeIndex > 0"
          src="../assets/img/left-icon-img2.png"
          alt=""
        />
      </div>
      <!-- 左切换按钮结束 -->
      <!-- 切换图片开始 -->
      <div class="img-change-content-sm">
        <!-- 切换图片容器开始 -->
        <div class="img-change-select-sm" ref="selectContent2">
          <div
            class="img-select-item-sm"
            v-for="(item, index) in lists"
            :key="index"
            @click="selectBigBanner(item)"
          >
            <img :src="item.img" style="width: 100%; height: 100%" />
          </div>
        </div>
        <!-- 切换图片容器开始 -->
        <!--  -->
      </div>
      <!-- 切换图片结束 -->
      <!-- 右切换按钮开始 -->
      <div class="sm-change-btn" @click="changeImgContent2(1)">
        <img
          v-show="bannerChangeIndex != 0"
          src="../assets/img/right-icon-img3.png"
          alt=""
        />
      </div>
      <!-- 右切换按钮结束 -->
    </div>
    <!-- 小屏切换结束 -->
    <!-- 底部内容开始 -->
    <div class="change-bottom hidden-xs-only">
      <div class="change-msg">{{ item.msg }}</div>
      <div class="phone">
        <img src="../assets/img/phone-img2.png" alt="" />
        <span>销售热线：027-84840000</span>
      </div>
    </div>
    <div class="change-bottom-sm hidden-sm-and-up">
      <div class="change-msg-sm">{{ item.msg }}</div>
      <div class="phone">
        <img src="../assets/img/phone-img2.png" alt="" />
        <span>销售热线：027-84840000</span>
      </div>
    </div>
    <!-- 底部内容结束 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      bannerSelectLists: [], //banner数据
      item: {}, //大图URL
      bannerChangeIndex: 0, //banner所在位置
    };
  },
  props: {
    lists: Array,
  },
  mounted() {
    this.item = this.lists[0];
  },
  methods: {
    // 选择切换图片
    selectBigBanner(value) {
      this.item = value;
    },
    // 切换按钮点击
    changeImgContent(value) {
      if (value == 0) {
        if (this.bannerChangeIndex < this.lists.length - 5) {
          this.bannerChangeIndex += 1;
          this.$refs.selectContent.style.marginLeft =
            "-" + 156 * this.bannerChangeIndex + "px";
        }
      } else if (value == 1) {
        if (this.bannerChangeIndex > 0) {
          this.bannerChangeIndex -= 1;
          this.$refs.selectContent.style.marginLeft =
            "-" + 156 * this.bannerChangeIndex + "px";
        }
      }
    },
    // 小屏切换按钮点击
    changeImgContent2(value) {
      if (value == 0) {
        if (this.bannerChangeIndex < this.lists.length - 4) {
          this.bannerChangeIndex += 1;
          this.$refs.selectContent2.style.marginLeft =
            "-" + 68 * this.bannerChangeIndex + "px";
        }
      } else if (value == 1) {
        if (this.bannerChangeIndex > 0) {
          this.bannerChangeIndex -= 1;
          this.$refs.selectContent2.style.marginLeft =
            "-" + 68 * this.bannerChangeIndex + "px";
        }
      }
    },
  },
};
</script>

<style scoped>
.img-change {
  position: relative;
}

.img-change-option {
  width: 100%;
  background: url("../assets/img/about-bg-img3.png") no-repeat center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  left: 0;
}

.img-change-content-sm {
  width: 264px;
  height: 34px;
  overflow: hidden;
  position: relative;
  margin: 0 16px;
}

.img-change-select-sm {
  transition: all 2s;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.img-select-item-sm {
  width: 60px;
  height: 34px;
  background-color: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  margin-right: 8px;
}

.sm-change-btn {
  width: 20px;
  cursor: pointer;
}

.img-change-sm {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.img-change-content {
  width: 760px;
  height: 86px;
  overflow: hidden;
  position: relative;
  margin: 0 60px;
}

.img-change-select {
  transition: all 2s;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.img-select-item {
  width: 136px;
  height: 86px;
  background-color: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  margin-right: 20px;
  padding: 8px;
}

.change-btn {
  width: 48px;
  cursor: pointer;
}

.change-bottom {
  height: 242px;
  background-color: #f5f5f5;
  box-sizing: border-box;
  padding: 60px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.change-msg {
  color: #333333;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
}

.phone {
  color: #333333;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 27px;
  display: flex;
  align-items: center;
}

.phone span {
  margin-left: 10px;
}

.change-bottom-sm {
  margin-top: 20px;
}

.change-msg-sm {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
}

.change-bottom-sm .phone {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.change-bottom-sm .phone span {
  margin-left: 10px;
}
</style>
