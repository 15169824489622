<template>
  <div class="content">
    <!-- 顶部导航开始 -->
    <div class="top">
      <!-- 顶部导航图片开始 -->
      <img src="../assets/img/contentUs-top-bg.png" alt="" />
      <!-- 顶部导航图片结束 -->
      <!-- 内容容器开始 -->
      <div class="container top-nav">
        <!-- 顶部导航开始 -->
        <my-header class="hidden-xs-only" active="7"></my-header>
        <language-change2
          class="hidden-sm-and-up"
          active="7"
        ></language-change2>
        <!-- 顶部导航结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 顶部导航结束 -->
    <!-- 内容开始 -->
    <div class="container">
      <!-- 标题部分开始 -->
      <div class="title">
        <!-- {{ $t("message.activeService") }} -->
        联系我们
        <span class="small"></span>
        <span class="big"></span>
      </div>
      <!-- 标题部分结束 -->
      <!-- 内容开始 -->
      <div class="middle-content hidden-xs-only">
        <el-row :gutter="40">
          <!-- 单一内容开始 -->
          <el-col class="mt-40" :xs="8" :sm="8">
            <div class="middle-item h-254">
              <!-- 标题开始 -->
              <div class="middle-item-title">
                <img src="../assets/svg/contentUs-phone-icon.svg" alt="" />
                <div class="middle-item-title-msg">公司电话</div>
              </div>
              <!-- 标题结束 -->
              <!-- 英文标题开始 -->
              <div class="middle-item-title2">Company Office Number</div>
              <!-- 英文标题结束 -->
              <!-- 单一子类开始 -->
              <div class="middle-item-sub">
                <!-- 子标题开始 -->
                <div class="middle-item-subTitle">联系电话</div>
                <!-- 子标题结束 -->
                <!-- 英文子标题开始 -->
                <div class="middle-item-subTitle2">contact number</div>
                <!-- 英文子标题结束 -->
                <!-- 子标题内容开始 -->
                <div class="middle-item-subMsg">027-86655088</div>
                <!-- 子标题内容结束 -->
              </div>
              <!-- 单一子类结束 -->
            </div>
          </el-col>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <el-col class="mt-40" :xs="8" :sm="8">
            <div class="middle-item h-254">
              <!-- 标题开始 -->
              <div class="middle-item-title">
                <img src="../assets/svg/contentUs-phone-icon.svg" alt="" />
                <div class="middle-item-title-msg">承接展业务部</div>
              </div>
              <!-- 标题结束 -->
              <!-- 英文标题开始 -->
              <div class="middle-item-title2">
                Undertaking exhibition business department
              </div>
              <!-- 英文标题结束 -->
              <!-- 单一子类开始 -->
              <div class="middle-item-sub">
                <!-- 子标题开始 -->
                <div class="middle-item-subTitle">联系电话</div>
                <!-- 子标题结束 -->
                <!-- 英文子标题开始 -->
                <div class="middle-item-subTitle2">contact number</div>
                <!-- 英文子标题结束 -->
                <!-- 子标题内容开始 -->
                <div class="middle-item-subMsg">027-84696948</div>
                <!-- 子标题内容结束 -->
              </div>
              <!-- 单一子类结束 -->
            </div>
          </el-col>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <el-col class="mt-40" :xs="8" :sm="8">
            <div class="middle-item h-254">
              <!-- 标题开始 -->
              <div class="middle-item-title">
                <img src="../assets/svg/contentUs-phone-icon.svg" alt="" />
                <div class="middle-item-title-msg">自办展业务部</div>
              </div>
              <!-- 标题结束 -->
              <!-- 英文标题开始 -->
              <div class="middle-item-title2">
                Self exhibition business department
              </div>
              <!-- 英文标题结束 -->
              <!-- 单一子类开始 -->
              <div class="middle-item-sub">
                <!-- 子标题开始 -->
                <div class="middle-item-subTitle">联系电话</div>
                <!-- 子标题结束 -->
                <!-- 英文子标题开始 -->
                <div class="middle-item-subTitle2">contact number</div>
                <!-- 英文子标题结束 -->
                <!-- 子标题内容开始 -->
                <div class="middle-item-subMsg">027-86655088-8703</div>
                <!-- 子标题内容结束 -->
              </div>
              <!-- 单一子类结束 -->
            </div>
          </el-col>
          <!-- 单一内容结束 -->
        </el-row>
      </div>
      <div class="middle-content-sm hidden-sm-and-up">
        <el-row :gutter="8">
          <!-- 单一内容开始 -->
          <el-col class="mt-12" :xs="12">
            <div class="middle-item-bg">
              <div class="middle-item h-200">
                <!-- 标题开始 -->
                <div class="middle-item-title">
                  <div class="middle-item-title-img">
                    <img src="../assets/svg/contentUs-phone-icon.svg" alt="" />
                  </div>
                  <div class="middle-item-title-msg">公司电话</div>
                </div>
                <!-- 标题结束 -->
                <!-- 英文标题开始 -->
                <div class="middle-item-title2">Company Office Number</div>
                <!-- 英文标题结束 -->
                <!-- 单一子类开始 -->
                <div class="middle-item-sub">
                  <!-- 子标题开始 -->
                  <div class="middle-item-subTitle">联系电话</div>
                  <!-- 子标题结束 -->
                  <!-- 英文子标题开始 -->
                  <div class="middle-item-subTitle2">contact number</div>
                  <!-- 英文子标题结束 -->
                  <!-- 子标题内容开始 -->
                  <div class="middle-item-subMsg">027-86655088</div>
                  <!-- 子标题内容结束 -->
                </div>
                <!-- 单一子类结束 -->
              </div>
            </div>
          </el-col>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <el-col class="mt-12" :xs="12">
            <div class="middle-item-bg">
              <div class="middle-item h-200">
                <!-- 标题开始 -->
                <div class="middle-item-title">
                  <div class="middle-item-title-img">
                    <img src="../assets/svg/contentUs-phone-icon.svg" alt="" />
                  </div>
                  <div class="middle-item-title-msg">承接展业务部</div>
                </div>
                <!-- 标题结束 -->
                <!-- 英文标题开始 -->
                <div class="middle-item-title2">
                  Undertaking exhibition business department
                </div>
                <!-- 英文标题结束 -->
                <!-- 单一子类开始 -->
                <div class="middle-item-sub">
                  <!-- 子标题开始 -->
                  <div class="middle-item-subTitle">联系电话</div>
                  <!-- 子标题结束 -->
                  <!-- 英文子标题开始 -->
                  <div class="middle-item-subTitle2">contact number</div>
                  <!-- 英文子标题结束 -->
                  <!-- 子标题内容开始 -->
                  <div class="middle-item-subMsg">027-84696948</div>
                  <!-- 子标题内容结束 -->
                </div>
                <!-- 单一子类结束 -->
              </div>
            </div>
          </el-col>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <el-col class="mt-12" :xs="24">
            <div class="middle-item-bg">
              <div class="middle-item h-200">
                <!-- 标题开始 -->
                <div class="middle-item-title">
                  <div class="middle-item-title-img">
                    <img src="../assets/svg/contentUs-phone-icon.svg" alt="" />
                  </div>
                  <div class="middle-item-title-msg">自办展业务部</div>
                </div>
                <!-- 标题结束 -->
                <!-- 英文标题开始 -->
                <div class="middle-item-title2">
                  Self exhibition business department
                </div>
                <!-- 英文标题结束 -->
                <!-- 单一子类开始 -->
                <div class="middle-item-sub">
                  <!-- 子标题开始 -->
                  <div class="middle-item-subTitle">联系电话</div>
                  <!-- 子标题结束 -->
                  <!-- 英文子标题开始 -->
                  <div class="middle-item-subTitle2">contact number</div>
                  <!-- 英文子标题结束 -->
                  <!-- 子标题内容开始 -->
                  <div class="middle-item-subMsg">027-86655088-8703</div>
                  <!-- 子标题内容结束 -->
                </div>
                <!-- 单一子类结束 -->
              </div>
            </div>
          </el-col>
          <!-- 单一内容结束 -->
        </el-row>
      </div>
      <!-- 内容结束 -->
      <!-- 地址开始 -->
      <div class="address-img">
        <div class="address-img-big">
          <img src="../assets/img/content-us-img.png" alt="" />
        </div>
        <!-- 地址内容开始 -->
        <div class="address-content hidden-xs-only">
          <div>
            <img src="../assets/img/left-img2.png" alt="" />
          </div>
          <div class="address-content-msg">
            <!-- 标题开始 -->
            <div class="address-title">
              <img src="../assets/svg/contentUs-location-icon.svg" alt="" />
              <div class="address-title-msg">联系地址</div>
            </div>
            <!-- 标题结束 -->
            <!-- 信息开始 -->
            <div class="address-msg">中国湖北省武汉市汉阳区鹦鹉大道619号</div>
            <!-- 信息结束 -->
          </div>
        </div>
        <div class="address-content-sm hidden-sm-and-up">
          <div class="address-content-sm-up">
            <img src="../assets/img/up-icon.png" alt="" />
          </div>
          <div class="address-content-msg">
            <!-- 标题开始 -->
            <div class="address-title">
              <div class="address-title-img">
                <img src="../assets/img/address-img.png" alt="" />
              </div>
              <div class="address-title-msg">联系地址</div>
            </div>
            <!-- 标题结束 -->
            <!-- 信息开始 -->
            <div class="address-msg">中国湖北省武汉市汉阳区鹦鹉大道619号</div>
            <!-- 信息结束 -->
          </div>
        </div>
        <!-- 地址内容结束 -->
      </div>
      <!-- 地址结束 -->
    </div>

    <!-- 内容结束 -->
  </div>
</template>

<script>
import ChangeImg from "../components/ChangeImg.vue";

export default {
  components: { ChangeImg },

  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.mt-40 {
  margin-top: 40px;
}

.mt-12 {
  margin-top: 12px;
}

.middle-item-bg {
  border-radius: 6px;
  background-color: #1691fa;
  padding-top: 2px;
}

.middle-item {
  border: 2px solid #ebf1ff;
  box-shadow: 0px 0px 60px #eeeeee;
  border-radius: 6px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 40px;
}

.h-254 {
  height: 254px;
}

.h-345 {
  height: 345px;
}

.h-200 {
  height: 200px;
}

.middle-item {
  padding: 12px;
}

.middle-item-title {
  display: flex;
  align-items: center;
}

.middle-item-title-img {
  width: 18px;
  height: 18px;
}

.middle-item-title-img img {
  width: 100%;
}

.middle-item-title-msg {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  margin-left: 8px;
}

.middle-item-title2 {
  height: 36px;
  color: #999999;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-bottom: 1px solid #e0eaff;
  line-height: 18px;
  padding: 4px 0 8px;
}

.middle-item-sub {
  margin-top: 20px;
}

.middle-item-subTitle {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
}

.middle-item-subTitle2 {
  color: #999999;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
}

.middle-item-subMsg {
  color: #004bc7;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 30px;
  margin-top: 6px;
}

.middle-item-subMsg + .middle-item-subMsg {
  margin-top: 10px;
}

.address-img {
  margin: 20px 0 80px;
  position: relative;
}

.address-img-big img {
  width: 100%;
}

.address-content {
  position: absolute;
  top: 100px;
  right: 100px;
  display: flex;
  align-items: center;
}

.address-content-msg {
  width: 270px;
  background: #004bc7;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 20px;
}

.address-title {
  border-bottom: 1px solid #2d6bd2;
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}

.address-title-msg {
  color: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 16px;
  margin-left: 10px;
}

.address-msg {
  color: #ffffff;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  margin-top: 12px;
}

.address-content-sm {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-flow: column;
  align-items: center;
}

.address-content-sm-up {
  margin: 0 50px -4px 0;
}

.address-title-img {
  width: 14px;
}

.address-title-img img {
  width: 100%;
}

@media (min-width: 768px) {
  .middle-item {
    padding: 40px;
  }

  .middle-item-title-msg {
    color: #333333;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin-left: 8px;
  }

  .middle-item-title2 {
    height: 36px;
    color: #999999;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    border-bottom: 1px solid #e0eaff;
    line-height: 18px;
    padding: 8px 0 16px;
  }

  .middle-item-sub {
    margin-top: 20px;
  }

  .middle-item-subTitle {
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 21px;
  }

  .middle-item-subTitle2 {
    color: #999999;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 21px;
  }

  .middle-item-subMsg {
    color: #004bc7;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 30px;
    margin-top: 6px;
  }

  .address-img {
    margin: 80px 0;
    position: relative;
  }

  .address-content-msg {
    width: 270px;
    height: 222px;
    background: #004bc7;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    box-sizing: border-box;
    padding: 20px;
  }

  .address-title {
    border-bottom: 1px solid #2d6bd2;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
  }

  .address-title-msg {
    color: #ffffff;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 30px;
    margin-left: 13px;
  }

  .address-msg {
    color: #ffffff;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 30px;
    margin-top: 20px;
  }
}
</style>
